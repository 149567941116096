<template>
	<div id="keypad">
		<div id="keypad-container" @click="closeKeypad($event)">
			<div class="keypad-grid">
				<div class="keypad-display">
					<div class="current-input" v-if="inputConfig.inputField === 'phoneNumber'">
						<span v-if="keypadInputString.length >= 3">(</span>{{ keypadInputString?.[0] }}{{ keypadInputString?.[1]
						}}{{ keypadInputString?.[2] }}<span v-if="keypadInputString.length >= 3">)</span>{{ keypadInputString?.[3]
						}}{{ keypadInputString?.[4] }}{{ keypadInputString?.[5] }}<span v-if="keypadInputString.length >= 6">-</span
						>{{ keypadInputString?.[6] }}{{ keypadInputString?.[7] }}{{ keypadInputString?.[8] }}{{ keypadInputString?.[9] }}
						{{ keypadInputString.toString().substring(15, keypadInputString.toString().length) }}
					</div>
					<div class="current-input" v-else>{{ keypadInputString }}</div>
				</div>
				<button @click="appendNumDisplay(7)">7</button>
				<button @click="appendNumDisplay(8)">8</button>
				<button @click="appendNumDisplay(9)">9</button>
				<button class="span-two-rows" @click="clearInput()">Clear</button>
				<button @click="appendNumDisplay(4)">4</button>
				<button @click="appendNumDisplay(5)">5</button>
				<button @click="appendNumDisplay(6)">6</button>
				<button @click="appendNumDisplay(1)">1</button>
				<button @click="appendNumDisplay(2)">2</button>
				<button @click="appendNumDisplay(3)">3</button>
				<button class="span-two-rows" @click="commitInput()">Enter</button>
				<button @click="appendNumDisplay(0)" class="span-three-col">0</button>
			</div>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
export default {
	name: "NumInputPad",
	props: {
		inputConfig: Object,
		currencyInfo: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			inputField: null,
			caller: "",
			keypadInput: 0,
			keypadInputString: "",
			keypadElement: null,
			systemCurrencyInfo: this.systemCurrencyTool.currencyInfo,
			systemMinorOrFull: this.systemCurrencyTool.displayType.minorOrFull,
		};
	},
	watch: {
		keypadInput() {
			if (this.inputConfig.inputField !== "phoneNumber" && this.inputConfig.inputField !== "accountAccessCode") {
				this.keypadInputString = this.systemCurrencyTool.formatFromAU(this.keypadInput, this.systemMinorOrFull);
			} else {
				this.keypadInputString = this.keypadInput.toString();
			}
		},
		inputConfig: {
			handler() {
				this.keypadInput = this.inputConfig.keypadInput || 0;
			},
			deep: true,
		},
	},
	onErrorCaptured(e) {
		console.error(e);
	},
	created() {
		const body = document.querySelector("body");
		onBeforeUnmount(() => {
			body.onkeydown = null;
		});
	},
	mounted() {
		let thisInstance = this;
		this.keypadInput = this.inputConfig.keypadInput || 0;

		this.keypadInputString = this.inputConfig.inputField === "phoneNumber" ? " " : "0";
		const body = document.querySelector("body");
		body.onkeydown = (e) => {
			e.preventDefault();
			let keyNumber = thisInstance.inputConfig.inputField ? Number(e.key) : 0;
			switch (e.key) {
				case "Enter":
					if (this.inputConfig.inputField) thisInstance.commitInput();
					break;
				case "Delete":
					thisInstance.clearInput();
					break;
				case "Escape":
					thisInstance.eventBus.emit("closeKeypad");
					break;
				case "Backspace":
					thisInstance.backspace();
					break;
				case keyNumber.toString():
					thisInstance.appendNumDisplay(keyNumber);
					break;
				case isNaN(keyNumber):
					break;
			}
		};
	},
	methods: {
		appendNumDisplay(numPadInput) {
			// this.systemSettings.allowPhoneExtensionDigits

			// if (this.keypadInput.toString().length < this.inputConfig.limitNumber) {
			let numString = `${this.keypadInput.toString()}${numPadInput.toString()}`;
			this.keypadInput = Number(numString);
			// }
		},
		clearInput() {
			this.keypadInput = 0;
		},
		backspace() {
			let numberString = this.keypadInput.toString();
			if (numberString.length <= 1) {
				this.keypadInput = 0;
				return;
			}
			numberString = numberString.slice(0, -1);
			this.keypadInput = Number(numberString);
		},
		commitInput() {
			let inputElement = document.querySelector(`[name="${this.inputConfig.inputField}"]`);
			let inputValue = 0;
			if (this.inputConfig.inputField !== "phoneNumber" && this.inputConfig.inputField !== "accountAccessCode") {
				inputElement.value = this.keypadInput || 0; // this.systemCurrencyInfo.auPerCurrencyUnit || 0;
			} else {
				inputElement.value = this.keypadInput || 0;
			}
			inputElement.dispatchEvent(new Event("input", { bubbles: true }));
			this.eventBus.emit("commitInputFromNumPad", this.keypadInput);
			this.eventBus.emit("closeKeypad");
		},
		closeKeypad(e = null) {
			if (
				e &&
				!(
					e.target.className === "keypad-grid" ||
					e.target.parentElement.className === "keypad-grid" ||
					e.target.className === "current-input"
				)
			) {
				this.eventBus.emit("closeKeypad");
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#keypad {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	right: 0;
	bottom: 60px;
	left: 0;
	background-color: rgba(0, 0, 0, 0.6);
	backdrop-filter: blur(4px);
	z-index: 100;
}

#keypad-container {
	display: grid;
	height: 100%;
	align-content: center;
}

.keypad-grid {
	position: relative;
	display: grid;
	justify-content: center;
	align-content: center;
	grid-template-columns: repeat(4, 100px);
	grid-template-rows: minmax(100px, auto) repeat(4, 100px);
	background: rgba(92, 89, 136, 0.38);
	margin: auto;
	border-radius: 1.25em;
	padding: 30px;
}

.keypad-grid > button {
	margin: 2px auto;
	min-width: 3em;
	font-size: 2rem;
	border: 1px solid #fff;
	outline: none;
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.6);
	transition: transform 0.2s;
}

.keypad-grid > button:hover {
	background-color: rgba(205, 221, 255, 0.8);
	transform: scale(0.98);
}

button.span-two-rows {
	grid-row: span 2;
	min-width: 3em;
}

button.span-three-col {
	grid-column: span 3;
	min-width: 9.25em;
}

.keypad-display {
	grid-column: 1 / -1;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	align-items: flex-end;
	justify-content: space-around;
	flex-direction: column;
	padding: 10px;
	word-wrap: break-word;
	word-break: break-all;
	border-radius: 8px;
	margin: 2px;
}

.keypad-display .current-input {
	color: #fff;
	font-size: 2.5rem;
}
</style>
